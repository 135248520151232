<template>
  <div class="fr_trace">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:update="update" v-bind:enableSelect="false" route="viewFactoryInventory" v-on:dataUpdated="dataUpdated" v-on:rowClicked="rowClicked" />
    </div>

    <!-- Modal -->
    <div class="modal fade" id="trace" tabindex="-1" aria-labelledby="trace" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <div class="fr_sectionTitle">{{ LabelTrace }}</div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <Trace v-bind:dataRow="dataRow" />
          </div>
          <div class="modal-footer">
            <div class="fr_primaryButton">
              <button type="button" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from '../store.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Trace from '@/components/Trace.vue';

export default {
  name: 'trace',
  components: {
    ViewDbData,
    Trace,
  },

  //----------------------------------------------------------------------
  data() {
    return {
      dataRow: null,
      update: false,
    };
  },

  //----------------------------------------------------------------------
  computed: mapState({
    LabelTitle: state => getLocale(state, 11501),
    LabelRecord: state => getLocale(state, 220001),
  }),

  //----------------------------------------------------------------------
  methods: {
    //----------------------------------------------------------------------
    rowClicked(params) {
      this.dataRow = params;
      const modal = new bootstrap.Modal(document.getElementById('trace'));
      modal.show();
    },

    //----------------------------------------------------------------------
    getStatusStr(status) {
      if (status == 'Procured') return getLocale(this.$store.state, 11509);
      if (status == 'Processing') return getLocale(this.$store.state, 11510);
      if (status == 'Finished') return getLocale(this.$store.state, 11511);
    },

    //----------------------------------------------------------------------
    dataUpdated() {
      this.update = false;
    },
  },
}

</script>
