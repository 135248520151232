<template>
  <!--ホーム画面の言語切り替えボタン(id/for必要)-->
  <div>
    <div class="fr_LangButtonWrap">
      <div>
        <input type="radio" v-model="lang" name="lang" id="kr" value="kr" v-on:change="changeLang">
        <label for="kr">{{ LabelLangKr }}</label>
      </div>
      <div>
        <input type="radio" v-model="lang" name="lang" id="jp" value="jp" v-on:change="changeLang">
        <label for="jp">{{ LabelLangJp }}</label>
      </div>
      <div>
        <input type="radio" v-model="lang" name="lang" id="en" value="en" v-on:change="changeLang">
        <label for="en">{{ LabelLangEn }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectLang',
  data() {
    return {
      lang: this.$store.getters.lang,
      LabelLangKr: '한국어',
      LabelLangJp: '日本語',
      LabelLangEn: 'English',
    };
  },

  methods: {
    changeLang() {
      this.$store.dispatch('setLang', this.lang);
    },
  },
}

</script>