<template>
<div class="fr_homeLogoutWrap">
  <div class="fr_homeText">HOME</div>
  <SelectLang />

  <div class="fr_loginContainer">
		<div class="fr_sectionTitle">ログインしています</div>
    <p> {{ user }} </p>

    <div class="fr_loginButtonWrap">
      <div class="fr_logoutButton"><button v-on:click="handleSignOut()">{{ LabelLogout }}</button></div>
    </div><!--/.fr_loginButtonWrap-->
    <div class="fr_passwordReset"><button v-if="enableTest" v-on:click="clearData()">clear all data</button></div>
  </div><!--/.fr_loginContainer-->
</div><!--/.fr_homeLogoutWrap-->
</template>

<script>
import Db from "../db";
import SelectLang from "./SelectLang.vue";
import { router } from '@/router';
import {
  getAuth,
  signOut,
} from 'firebase/auth';
import { getLocale } from '@/store.js';

export default {
  name: 'Home',
  components: {
    SelectLang,
  },
  emits: ['onLogout'],

  //----------------------------------------------------------------------
  data() {
    return {
      auth: getAuth(),
      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  computed: {
    user: function () {
      if (!this.auth.currentUser) return '';
      if (!this.auth.currentUser.displayName) return this.auth.currentUser.email;
      return this.auth.currentUser.displayName;
    },
    LabelLogout: function () { return getLocale(this.$store.state, 280012); },
  },

  //----------------------------------------------------------------------
  methods: {
    handleSignOut() {
      signOut(this.$store.getters.auth).then(() => {
        this.$store.dispatch('setLoginStatus', {
          login: false,
          user: null
        });
        this.$emit('onLogout');
        router.push('/');
      }).catch((error) => {
        console.log(error);
      });
    },

    async clearData() {
      const table1 = [
        'FactoryInventory',
        'RecProcure',
        'RecProcess',
        'RecDetails',
        'RecMove',
        'RecSales',
        'RecPaymentCollect',
        'Log',
      ];
      for (let i = 0; i < table1.length; i++) {
        const items = await Db.loadData(this.$store.getters.dbDataFilename, table1[i]);
        await Db.deleteData(this.$store.getters.dbDataFilename, table1[i], items);
      }

      const table2 = [
        'SupplierThread',
        'SupplierCloth',
        'Customer',
      ];
      for (let i = 0; i < table2.length; i++) {
        const items = await Db.loadData(this.$store.getters.dbDataFilename, table2[i]);
        for (let j = 0; j < items.length; j++) {
          await Db.updateData(this.$store.getters.dbDataFilename, table2[i], [{
            id: items[j].id,
            amount: 0,
          }]);
        }
      }

      const table3 = [
        'Cloth',
        'Product',
      ];
      for (let i = 0; i < table3.length; i++) {
        const items = await Db.loadData(this.$store.getters.dbDataFilename, table3[i]);
        for (let j = 0; j < items.length; j++) {
          await Db.updateData(this.$store.getters.dbDataFilename, table3[i], [{
            id: items[j].id,
            material: '',
          }]);
        }
      }

      alert("All data cleared");
    },
  },
}

</script>