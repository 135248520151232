<template>
  <div class="fr_entryForm">
    <div>
      <div class="fr_sectionTitle">{{ LabelSearch }}</div>
      <ul class="fr_selectList fr_traceSearch">
        <li class="fr_datapickerWrap">
          <p>{{ LabelDateBegin }}</p>
          <VueDatePicker v-model="DateBegin" :enable-time-picker="false" format="yyyy/MM/dd" @update:modelValue="OnConditionChange" :teleport="true" />
        </li>
        <li class="fr_datapickerWrap">
          <p>{{ LabelDateEnd }}</p>
          <VueDatePicker v-model="DateEnd" :enable-time-picker="false" format="yyyy/MM/dd" @update:modelValue="OnConditionChange" :teleport="true" />
        </li>
        <li v-for="item in Items" :key="item">
          <div class="fr_selecterWrap">
            <select v-model="LBSelected[item.field]" v-on:change="OnConditionChange">
              <option :value="LBSelectedNone[item.field]">{{ item.label }}</option>
              <option v-for="listItem in LBLists[item.field]" :value="listItem" :key="listItem">{{ listItem }}</option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from '../store.js';

export default {
  name: 'search',
  props: ['tableItems', 'tableColumns'],
  emits: ['searchConditionChanged'],

  //----------------------------------------------------------------------
  data() {
    return {
      DateBegin: null,
      DateEnd: new Date(),
      Items: [],
      LBLists: {},
      LBSelected: {},
      LBSelectedNone: {},
    }
  },

  //----------------------------------------------------------------------
  computed: mapState({
    LabelSearch: state => getLocale(state, 11521),
    LabelDateBegin: state => getLocale(state, 11522),
    LabelDateEnd: state => getLocale(state, 11523),
  }),

  //----------------------------------------------------------------------
  watch: {
    tableItems: function () {
      this.setData();
    },
  },

  //----------------------------------------------------------------------
  methods: {
    setData() {
      this.Items = [];
      for (let i = 0; i < this.tableColumns.length; i++) {
        if (!this.tableColumns[i].search) continue;

        // ラベルを追加
        const item = this.tableColumns[i];
        this.Items.push(item);

        this.LBLists[item.field] = [];
        this.tableItems.forEach((tableItem) => {
          if (!this.LBLists[item.field].includes(tableItem[item.field])) {
            this.LBLists[item.field].push(tableItem[item.field]);
          }
        });
        this.LBSelected[item.field] = '';
        this.LBSelectedNone[item.field] = '';
      }
    },

    OnConditionChange: function () {
      this.$emit("searchConditionChanged", {
        DateBegin: this.DateBegin,
        DateEnd: this.DateEnd,
        LBSelected: this.LBSelected,
      });
    },

  }
}

</script>