<template>
  <div class="fr_entryForm">
    <div>
      <div class="fr_sectionTitle">追跡結果</div>
      <table class="fr_dataTable fr_dataTable-traceResultVertical">
        <tbody>
          <tr>
            <td>{{ LabelProductName }}</td>
            <td>{{ TBProductName }}</td>
          </tr>
          <tr>
            <td>{{ LabelMaterialCost }}</td>
            <td>{{ TBMaterialCost }}</td>
          </tr>
          <tr>
            <td>{{ LabelProcessCost }}</td>
            <td>{{ TBProcessCost }}</td>
          </tr>
          <tr>
            <td>{{ LabelAmount }}</td>
            <td>{{ TBAmount }}</td>
          </tr>
          <tr>
            <td>{{ LabelStatus }}</td>
            <td>{{ TBStatus }}</td>
          </tr>
        </tbody>
      </table>

      <div class="fr_sectionTitle">{{ LabelTraceData }}</div>
      <table class="fr_dataTable fr_dataTable-traceResultData">
        <thead>
          <th v-for="(col, key) in TraceColumn" :key="key" align="left">{{ col.name }}</th>
        </thead>
        <tr v-for="(item, key) in TraceData" :key="key">
          <td v-for="(col, keyCol) in TraceColumn" :key="keyCol" align="left">{{ item[col.code] }}</td>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from '../store.js';
import CTrace from './CTrace.js';

export default {
  name: 'trace',
  props: ['dataRow'],

  //----------------------------------------------------------------------
  data() {
    return {
      TBProductName: null,
      TBMaterialCost: null,
      TBProcessCost: null,
      TBAmount: null,
      TBStatus: null,

      TraceData: null,
      TraceColumn: null,

      trace: null,
      update: false,
    };
  },

  //----------------------------------------------------------------------
  computed: mapState({
    LabelTraceData: state => getLocale(state, 11505),
    LabelProductName: state => getLocale(state, 11506),
    LabelMaterialCost: state => getLocale(state, 11503),
    LabelProcessCost: state => getLocale(state, 11504),
    LabelAmount: state => getLocale(state, 220008),
    LabelStatus: state => getLocale(state, 220010),
  }),

  //----------------------------------------------------------------------
  async created() {
    this.trace = new CTrace.CTrace();
    this.trace.init(this.$store.getters.dbDataFilename, this.$store.state);
  },  

  watch: {
    dataRow: function (item) {
      if (item.status == 'Procured') this.showTraceProcure(item);
      else this.showTraceProcess(item);
    }
  },

  //----------------------------------------------------------------------
  methods: {
    //----------------------------------------------------------------------
    async showTraceProcure(item) {
      this.TraceColumn = [
        { code: 'date', name: getLocale(this.$store.state, 20013), },
        { code: 'ppu', name: getLocale(this.$store.state, 20008), },
        { code: 'amount', name: getLocale(this.$store.state, 20009), },
        { code: 'bd', name: getLocale(this.$store.state, 20014), },
      ];

      const traceData = await this.trace.traceProcure(item.id);
      this.TraceData = [{
        date: traceData.dateOfMaterial,
        ppu: traceData.ppu,
        amount: traceData.amount,
        bd: traceData.bdOfMaterial,
      }];
      this.TBProductName = item.itemName;
      this.TBMaterialCost = traceData.costMaterial;
      this.TBProcessCost = 0;
      this.TBAmount = item.amount;
      this.TBStatus = item.statusStr;
    },

    //----------------------------------------------------------------------
    async showTraceProcess(item) {
      this.TraceColumn = [
        { code: 'date', name: getLocale(this.$store.state, 11512), },
        { code: 'itemName', name: getLocale(this.$store.state, 11513), },
        { code: 'materialAmount', name: getLocale(this.$store.state, 11515) },
        { code: 'materialAmountBd', name: getLocale(this.$store.state, 11516) },
        { code: 'finishAmount', name: getLocale(this.$store.state, 11519) },
        { code: 'finishAmountBd', name: getLocale(this.$store.state, 11520) },
        { code: 'loss', name: getLocale(this.$store.state, 11524) },
        { code: 'ppu', name: getLocale(this.$store.state, 11514) },
        { code: 'materialCost', name: getLocale(this.$store.state, 11517) },
        { code: 'processCost', name: getLocale(this.$store.state, 11518) },
      ];

      const traceData = await this.trace.traceProcess(item.id);
      console.log(traceData);

      this.TraceData = [];
      for (let i = 0; i < traceData.dat.length; i++) {
        this.TraceData.push({
          date: traceData.dat[i].date,
          itemName: traceData.dat[i].itemName,
          materialAmount: traceData.dat[i].materialAmount,
          materialAmountBd: traceData.dat[i].materialAmountBd,
          finishAmount: traceData.dat[i].finishAmount,
          finishAmountBd: traceData.dat[i].finishAmountBd,
          loss: traceData.dat[i].loss,
          ppu: traceData.dat[i].ppu,
          materialCost: traceData.dat[i].materialCost,
          processCost: traceData.dat[i].processCost,
        });
      }
      console.log(this.TraceData);
      this.TBProductName = item.itemName;
      this.TBMaterialCost = traceData.costMaterial.toFixed(0);
      this.TBProcessCost = traceData.costProcess.toFixed(0);
      this.TBAmount = item.amount;
      this.TBStatus = item.statusStr;
    },
  }
}

</script>