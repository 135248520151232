<template>
  <div class="fr_pageTitle">{{ LabelTitle }}</div>
  <div class="fr_dataView">
    <vue-good-table :columns="tableColumns" :rows="tableItems" :pagination-options="{ enabled: true }" styleClass="fr_dataTable" />
  </div>

</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from '../store.js';
import Db from "@/db";
import Util from '@/util';

export default {
  name: 'Log',
  props: { route: String },
  emits: ['onLogout'],

  data() {
    return {
      tableItems: [],
      tableColumns: [],
      sortBy: [{ key: 'id', order: 'desc' }],
    };
  },

  //----------------------------------------------------------------------
  computed: mapState({
    LabelTitle: state => getLocale(state, 290001),
    LabelDateTime: state => getLocale(state, 290002),
    LabelType: state => getLocale(state, 290003),
    LabelDetail: state => getLocale(state, 290004),
  }),

  created() {
    this.tableColumns = [{
      label: this.LabelDateTime,
      field: 'datetime',
    }, {
      label: this.LabelType,
      field: 'type',
    }, {
      label: this.LabelDetail,
      field: 'detail',
    }];
    this.loadData();
  },

  methods: {
    //---------------------------------------------------------------------------------------------------
    async loadData() {
      this.tableItems = [];
      let data = await Db.loadData(this.$store.getters.dbDataFilename, 'Log');
      const temp = JSON.parse(JSON.stringify(Db.arrayToObj(data)));
      data = Object.keys(temp).map(function (key) {
        return temp[key];
        //idでソート
      }).sort(function (a, b) {
        return (a.id > b.id) ? -1 : 1;
      });
      for (let i = 0; i < data.length; i++) {
        this.tableItems.push({
          datetime: (new Date(data[i].datetime)).toLocaleString(),
          type: this.getTypeString(data[i].type),
          detail: await this.getDetail(data[i].type, data[i].pid)
        });
      }
    },

    //---------------------------------------------------------------------------------------------------
    getTypeString(type) {
      if (type == 'procureThread') return getLocale(this.$store.state, 10002);
      if (type == 'procureCloth') return getLocale(this.$store.state, 10003);
      if (type == 'startProductionCloth') return getLocale(this.$store.state, 10004);
      if (type == 'startProductionProduct') return getLocale(this.$store.state, 10005);
      if (type == 'startDyeingCloth') return getLocale(this.$store.state, 10015);
      if (type == 'finishProductionCloth') return getLocale(this.$store.state, 10006);
      if (type == 'finishProductionProduct') return getLocale(this.$store.state, 10007);
      if (type == 'finishDyeingCloth') return getLocale(this.$store.state, 10016);
      if (type == 'moveThread') return getLocale(this.$store.state, 10008);
      if (type == 'moveCloth') return getLocale(this.$store.state, 10009);
      if (type == 'moveProduct') return getLocale(this.$store.state, 10010);
      if (type == 'salesProduct') return getLocale(this.$store.state, 10013);
      if (type == 'paymentThread') return getLocale(this.$store.state, 10011);
      if (type == 'paymentCloth') return getLocale(this.$store.state, 10012);
      if (type == 'collectReceivable') return getLocale(this.$store.state, 10014);
    },

    //---------------------------------------------------------------------------------------------------
    async getDetail(type, pid) {
      if (type == 'procureThread') return await this.getDetailProcure(type, pid);
      if (type == 'procureCloth') return await this.getDetailProcure(type, pid);
      if (type == 'startProductionCloth') return await this.getDetailProcess(type, pid);
      if (type == 'startProductionProduct') return await this.getDetailProcess(type, pid);
      if (type == 'startDyeingCloth') return await this.getDetailProcess(type, pid);
      if (type == 'finishProductionCloth') return await this.getDetailProcess(type, pid);
      if (type == 'finishProductionProduct') return await this.getDetailProcess(type, pid);
      if (type == 'finishDyeingCloth') return await this.getDetailProcess(type, pid);
      if (type == 'moveThread') return await this.getDetailMove(type, pid);
      if (type == 'moveCloth') return await this.getDetailMove(type, pid);
      if (type == 'moveProduct') return await this.getDetailMove(type, pid);
      if (type == 'salesProduct') return await this.getDetailSales(pid);
      if (type == 'paymentThread') return await this.getDetailPaymentCollect(type, pid);
      if (type == 'paymentCloth') return await this.getDetailPaymentCollect(type, pid);
      if (type == 'collectReceivable') return await this.getDetailPaymentCollect(type, pid);
    },

    //---------------------------------------------------------------------------------------------------
    async getDetailProcure(type, pid) {
      const data = await Db.loadDataOne(this.$store.getters.dbDataFilename, 'RecProcure', pid);
      const supplier = await Db.loadDataOne(this.$store.getters.dbDataFilename, type == 'procureThread' ? 'SupplierThread' : 'SupplierCloth', data[0].supplierId);
      const item = await Db.loadDataOne(this.$store.getters.dbDataFilename, type == 'procureThread' ? 'Thread' : 'Cloth', data[0].itemId);
      const delivery = await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Factory', data[0].deliveryId);
      let str = data[0].date + ' ／ ';
      str += getLocale(this.$store.state, 20004) + ': ' + supplier[0].name + ' ／ ';
      str += getLocale(this.$store.state, 20007) + ': ' + item[0].name + ' ／ ';
      str += getLocale(this.$store.state, 20008) + ': ' + data[0].pricePerUnit + ' ／ ';
      str += getLocale(this.$store.state, 220105) + ': ' + data[0].amount;
      str += (data[0].isLen ? 'm' : 'kg') + ' ／ ';
      str += getLocale(this.$store.state, 10214) + ': ' + Util.getAmountBdSimple(JSON.parse(data[0].amountBd)) + ' ／ ';
      str += getLocale(this.$store.state, 20016) + ': ' + delivery[0].name + ' ';
      return str;
    },

    //---------------------------------------------------------------------------------------------------
    async getDetailProcess(type, pid) {
      const data = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'RecProcess', pid))[0];
      const factory = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Factory', data.factoryId))[0];
      const item = (await Db.loadDataOne(this.$store.getters.dbDataFilename,
        (type == 'startProductionCloth' || type == 'startDyeingCloth' || type == 'finishProductionCloth' || type == 'finishDyeingCloth') ? 'Cloth' : 'Product',
        data.itemId))[0];
      let str = data.date + ' ／ ';
      str += getLocale(this.$store.state, 30004) + ': ' + factory.name + ' ／ ';
      str += getLocale(this.$store.state, 30007) + ': ' + item.name + ' ／ ';
      str += getLocale(this.$store.state, data.isLen ? 30008 : 30009) + ': ' + data.materialAmount + ' ／ ';
      str += getLocale(this.$store.state, 30014) + ': ' + Util.getAmountBdSimple(JSON.parse(data.amountBd)['pre']);
      if (type == 'finishProductionCloth' || type == 'finishProductionProduct' || type == 'finishDyeingCloth') {
        str += ' ／ ';
        str += getLocale(this.$store.state, data.isLen ? 30010 : 30011) + ': ' + data.finishAmount + ' ／ ';
        str += getLocale(this.$store.state, 30014) + ': ' + Util.getAmountBdSimple(JSON.parse(data.amountBd)['post']);
      }
      if (type == 'startDyeingCloth' || type == 'finishDyeingCloth') {
        str += ' ／ ';
        const color = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Color', data.colorId))[0];
        str += getLocale(this.$store.state, 30015) + ': ' + color.name;
      }
      return str;
    },

    //---------------------------------------------------------------------------------------------------
    async getDetailMove(type, pid) {
      const data = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'RecMove', pid))[0];
      const src = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Factory', data.srcId))[0];
      const dst = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Factory', data.dstId))[0];
      const item = (await Db.loadDataOne(this.$store.getters.dbDataFilename,
        (type == 'moveThread' ? 'Thread' : (type == 'moveCloth' ? 'Cloth' : 'Product')),
        data.itemId))[0];
      let str = data.date + ' ／ ';
      str += getLocale(this.$store.state, 210004) + ': ' + src.name + ' ／ ';
      str += getLocale(this.$store.state, 210006) + ': ' + dst.name + ' ／ ';
      str += getLocale(this.$store.state, 210007) + ': ';
      str += getLocale(this.$store.state, data.itemType == 'Thread' ? 160001 : (data.itemType == 'Cloth' ? 170001 : 180001)) + ' ／ ';
      str += getLocale(this.$store.state, 210009) + ': ' + item.name + ' ／ ';
      str += getLocale(this.$store.state, data.isLen ? 210010 : 210011) + ': ' + data.amount + ' ／ ';
      str += getLocale(this.$store.state, 210013) + ': ' + Util.getAmountBdSimple(JSON.parse(data.amountBd));
      return str;
    },

    //---------------------------------------------------------------------------------------------------
    async getDetailSales(pid) {
      const data = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'RecSales', pid))[0];
      const customer = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Customer', data.customerId))[0];
      const item = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'Product', data.itemId))[0];
      let str = data.date + ' ／ ';
      str += getLocale(this.$store.state, 250004) + ': ' + customer.name + ' ／ ';
      str += getLocale(this.$store.state, 250006) + ': ' + item.name + ' ／ ';
      str += getLocale(this.$store.state, 250007) + ': ' + data.pricePerUnit + ' ／ ';
      str += getLocale(this.$store.state, 250013) + ': ' + data.amount;
      return str;
    },

    //---------------------------------------------------------------------------------------------------
    async getDetailPaymentCollect(type, pid) {
      const data = (await Db.loadDataOne(this.$store.getters.dbDataFilename, 'RecPaymentCollect', pid))[0];
      const dealer = (await Db.loadDataOne(this.$store.getters.dbDataFilename,
        (type == 'paymentThread' ? 'SupplierThread' : (type == 'paymentCloth' ? 'SupplierCloth' : 'Customer')),
        data.dealerId))[0];
      let str = data.date + ' ／ ';
      str += getLocale(this.$store.state, 40005) + ': ' + dealer.name + ' ／ ';
      str += getLocale(this.$store.state, 40006) + ': ' + data.amount;
      return str;
    },
  }
}
</script>