//=====================================================================
// API to access DB
//

export default {
  reduceAccounts,
  addRecPaymentCollect,
};

import Db from '@/db.js';

//----------------------------------------------------------------------
async function reduceAccounts(dbDataFilename, tableName, data) {
  return Db.updateData(dbDataFilename, tableName,
    [{
      id: data.dealer.id,
      amount: data.dealer.amount - data.amount,
    }]);
}

//----------------------------------------------------------------------
async function addRecPaymentCollect(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'RecPaymentCollect',
    [{
      id: data.id,
      dealType: data.dealType,
      dealerId: data.dealer.id,
      amount: data.amount,
      date: data.date,
    }]);
}