<template>
  <li v-for="(value, index) in Values" :key="index">
    <input type="number" v-model="Values[index]" @blur="emit" />
  </li>
</template>

<style>
.font-class-name * {
  font-size: 12px;
}

.right-input input {
  text-align: right;
}
</style>

<script>

export default {
  name: 'ValueInput',
  props: ['Values'],
  emits: ['valueUpdated'],

  data() {
    return {
      amount: null,
    };
  },

  methods: {
    emit() {
      this.$emit("valueUpdated", this.Values);
    }
  },
}

</script>