//=====================================================================
// API to access DB
//

export default {
  executeProcure,
};

import Db from '@/db.js';
import Log from './Log.js';

async function executeProcure(params) {
  for (let i = 0; i < params.length; i++) {
    if (await executeProcureOne(params[i]) == false) return false;
  }
  return true;
}

//----------------------------------------------------------------------
async function executeProcureOne(params) {
  if (!params.itemType) throw "invalid itemType";

  // データベース書き込みの共通パラメーター
  const dbDataCommon = {
    supplierId: params.supplierId,
    itemType: params.itemType,
    itemId: params.itemId,
    deliveryId: params.deliveryId,
    pricePerUnit: params.pricePerUnit,
    isLen: params.isLen,
    amount: params.amount,
    amountBd: params.amountBd,
    memo: '',
    date: params.date,
  };
  const lastId = await Db.getLastId(params.dbDataFilename);
  const procureId = lastId + 1;
  const factoryInventoryId = lastId + 2;
  const moveId = lastId + 3;
  const logId = lastId + 4;
  
  // 購入記録に追加
  const responseAddRecProcure = await addRecProcure(
    params.dbDataFilename,
    {
      ...dbDataCommon,
      id: procureId,
    });
  if (responseAddRecProcure.result != "ok") {
    alert("fail\n" + responseAddRecProcure.error.errorInfo);
    return false;
  }

  // 工場在庫に追加
  const responseAddFactoryInventory = await addFactoryInventory(
    params.dbDataFilename,
    {
      ...dbDataCommon,
      id: factoryInventoryId,
      recordId: procureId,
    });
  if (responseAddFactoryInventory.result != "ok") {
    alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
    return false;
  }

  // 買掛金を追加
  const responseAddAccountsPayable = await addAccountsPayable(
    params.dbDataFilename,
    params.supplier,
    {
      ...dbDataCommon,
      amount: params.pricePerUnit * params.amount,
    });
  if (responseAddAccountsPayable.result != "ok") {
    alert("fail\n" + responseAddAccountsPayable.error.errorInfo);
    return false;
  }

  // 移動記録を追加
  const responseAddRecMove = await addRecMoveProcure(
    params.dbDataFilename,
    {
      ...dbDataCommon,
      id: moveId,
    });
  if (responseAddRecMove.result != "ok") {
    alert("fail\n" + responseAddRecMove.error.errorInfo);
    return false;
  }

  // ログを追加
  const responseAddLog = await Log.addLog(
    params.dbDataFilename, {
      id: logId,
      datetime: Date().toString(),
      pid: procureId,
      type: params.itemType == 'Thread' ? 'procureThread' : 'procureCloth',
  });

  return true;
}

//----------------------------------------------------------------------
async function addRecProcure(dbDataFilename, data) {
  const amountBd = JSON.stringify(data.amountBd);
  return Db.addData(dbDataFilename, 'RecProcure',
    [{
      id: data.id,
      supplierId: data.supplierId,
      itemType: data.itemType,
      itemId: data.itemId,
      deliveryId: data.deliveryId,
      pricePerUnit: data.pricePerUnit,
      isLen: data.isLen,
      amount: data.amount,
      amountBd: amountBd.replaceAll('"', '""'),
      memo: data.memo,
      date: data.date,
    }]);
}

//----------------------------------------------------------------------
async function addFactoryInventory(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'FactoryInventory',
    [{
      id: data.id,
      factoryId: data.deliveryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      amount: data.amount,
      status: 'Procured',
      recordId: data.recordId,
      date: data.date,
      colorId: 0,
    }]);
}

//----------------------------------------------------------------------
async function addAccountsPayable(dbDataFilename, tableName, data) {
  const supplier = await Db.loadDataOne(dbDataFilename, tableName, data.supplierId);

  return Db.updateData(dbDataFilename, tableName,
    [{
      id: data.supplierId,
      amount: supplier[0].amount + data.amount,
    }]);
}

//----------------------------------------------------------------------
async function addRecMoveProcure(dbDataFilename, data) {
  const amountBd = JSON.stringify(data.amountBd);
  return Db.addData(dbDataFilename, 'RecMove',
    [{
      id: data.id,
      srcId: 0,
      dstId: data.deliveryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      amount: data.amount,
      date: data.date,
      amountBd: amountBd.replaceAll('"', '""'),
    }]);
}
