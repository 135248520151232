<template>
  <div class="fr_topFirstView">
    <!--ホーム画面の大きいロゴ-->
    <div class="fr_topLogo">
      <img src="./assets/img/logo.png" alt="">
      <p>{{ LabelMailTitle }}</p>
    </div>
    <SelectLang />


    <div class="fr_loginContainer">
      <div class="fr_sectionTitle">ログイン</div>
      <div class="fr_inputSingle"><input type="text" v-model="email" :placeholder="LabelEmailAddress"></div>
      <div class="fr_inputSingle margin8"><input type="password" v-model="password" :placeholder="LabelPassword"></div>
      <div v-if="errMsg" class="fr_errMsg">{{ errMsg }}</div>
      <div class="fr_passwordReset"><button v-on:click="resetPassword">{{ LabelResetPassword }}</button></div>
      <div class="fr_loginButtonWrap">
        <div class="fr_loginButton"><button v-on:click="login">{{ LabelLogin }}</button></div>
        <div class="googleButton"><button v-on:click="signInWithGoogle"><img src="./assets/img/google_branding_guideline.svg" alt="{{ LabelSignInWithGoogle }}"></button></div>
      </div><!--/.fr_loginButtonWrap-->
    </div><!--/.fr_loginContainer-->
  </div><!--/.fr_topFirstView-->

</template>

<script>
import SelectLang from '@/components/SelectLang.vue';
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { getLocale } from '@/store.js';

export default {
  name: 'Login',
  components: {
    SelectLang,
  },
  emits: ['onLogin'],

  data() {
    return {
      email: '',
      password: '',
      errMsg: '',
      auth: getAuth(),
      isLoggedIn: false,
      firstLogin: false,
    };
  },

  computed: {
    LabelMailTitle: function () { return getLocale(this.$store.state, 1000); },
    LabelSwatchAILogin: function () { return getLocale(this.$store.state, 280001); },
    LabelEmailAddress: function () { return getLocale(this.$store.state, 280002); },
    LabelPassword: function () { return getLocale(this.$store.state, 280003); },
    LabelLogin: function () { return getLocale(this.$store.state, 280004); },
    LabelResetPassword: function () { return getLocale(this.$store.state, 280005); },
    LabelSignInWithGoogle: function () { return getLocale(this.$store.state, 280006); },
    LabelInvalidEmailAddress: function () { return getLocale(this.$store.state, 280007); },
    LabelUserNotFound: function () { return getLocale(this.$store.state, 280008); },
    LabelIncorrectPassword: function () { return getLocale(this.$store.state, 280009); },
    LabelSentEmailPasswordReset: function () { return getLocale(this.$store.state, 280010); },
    LabelErrorSentEmailPasswordReset: function () { return getLocale(this.$store.state, 280011); },
  },

  mounted() {
    this.$store.dispatch('setAuth', this.auth);
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
      this.$store.dispatch('setLoginStatus', {
        login: this.isLoggedIn,
        user: (this.isLoggedIn ? this.auth.currentUser : null),
      });
      if (this.isLoggedIn) this.$emit('onLogin', this.firstLogin);
    });
  },

  methods: {
    login() {
      this.firstLogin = true;
      signInWithEmailAndPassword(this.auth, this.email, this.password)
        .then(() => {
          this.isLoggedIn = true;
        })
        .catch((error) => {
          console.log(error.code)
          this.isLoggedIn = false;
          this.$store.dispatch('setLoginStatus', {
            login: false,
            user: null,
          });
          switch (error.code) {
            case 'auth/invalid-email':
              this.errMsg = this.LabelInvalidEmailAddress;
              break;
            case 'auth/user-not-found':
              this.errMsg = this.LabelUserNotFound;
              break;
            case 'auth/wrong-password':
              this.errMsg = this.LabelIncorrectPassword;
              break;
          }
        });
    },

    signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      this.firstLogin = true;
      signInWithPopup(this.auth, provider)
        .then(() => {
          this.isLoggedIn = true;
        }).catch((error) => {
          console.log(error);
        });
    },

    resetPassword() {
      sendPasswordResetEmail(this.auth, this.email).then(() => {
        // 再設定のE-Mailの送信成功
        alert(this.LabelSentEmailPasswordReset);
      }).catch((error) => {
        // 再設定のE-Mailの送信処理中にエラー
        alert(this.LabelErrorSentEmailPasswordReset);
      });
    },
  }
}

</script>