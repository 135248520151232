import Db from '@/db';
import ColumnInfo from '@/components/ColumnInfo.js';

class CTrace {
  initialized = false;

  async init(dbDataFilename) {
    if (this.initialized == true) return;
    this.dbDataFilename = dbDataFilename;

    this.tableFactoryInventory = ColumnInfo.setTypeFactoryInventory(await Db.loadData(this.dbDataFilename, 'FactoryInventory'));
    this.tableRecProcure = await Db.loadData(this.dbDataFilename, 'RecProcure');
    this.tableRecProcess = ColumnInfo.setTypeRecProcess(await Db.loadData(this.dbDataFilename, 'RecProcess'));
    this.tableRecDetails = ColumnInfo.setTypeRecDetails(await Db.loadData(this.dbDataFilename, 'RecDetails'));
    this.tableThread = await Db.loadData(this.dbDataFilename, 'Thread');
    this.tableCloth = await Db.loadData(this.dbDataFilename, 'Cloth');
    this.tableProduct = await Db.loadData(this.dbDataFilename, 'Product');

    this.initialized = true;
  }

  //----------------------------------------------------------------------------------------
  async traceProcure(id) {
    const pid = this.getPid(id);
    const ppu = this.tableRecProcure.find((r) => { return r.id == pid; }).pricePerUnit;
    const amount = this.tableRecProcure.find((r) => { return r.id == pid; }).amount;
    const item = this.getItem(id);
    const trace = item.name + ', pricePerUnit: ' + Number(ppu) + ', Amount: ' + Number(amount)
      + ', Cost: ' + Number(ppu * amount) + ', ID:' + id + '\n';

    return {
      costMaterial: ppu * amount,
      trace: trace,
      ppu: ppu,
      amount: amount,
      dateOfMaterial: this.getDateOfMaterial(id),
      bdOfMaterial: this.getBdOfMaterial(id),
    }
  }

  //----------------------------------------------------------------------------------------
  async traceProcess(id) {
    let costProcess = 0;
    let costMaterial = 0;

    const inventory = this.tableFactoryInventory.find((r) => { return r['id'] == id; });
    const pid = this.getPid(inventory.id);
    const amountMaterial = this.tableRecProcess.find((r) => { return r['id'] == pid; }).materialAmount;
    const amountFinish = this.tableRecProcess.find((r) => { return r['id'] == pid; }).finishAmount;

    let materials = [{
      inventoryId: inventory.id,
      amount: amountFinish > 0 ? amountFinish : amountMaterial,
    }];

    let i = 0;
    do {
      const mat = this.getMaterialOfProduct(materials[i]);
      if (mat) {
        materials = materials.concat(mat.materials);
        materials[i].loss = mat.loss;
      }
      else {
        materials[i].loss = 0;
      }
      i++;
    } while (i < materials.length);

    let dat = [];
    for (let i = 0; i < materials.length; i++) {
      const inventory = this.tableFactoryInventory.find((r) => { return r.id == materials[i].inventoryId; });
      const status = inventory.status;
      const pid = inventory.recordId;
      let costPr = '', ppu = '', costMat = '';
      if (status != 'Procured') {
        const process = this.tableRecProcess.find((r) => { return r.id == pid; });
        costPr = process.cost * (process.finishAmount ? materials[i].amount / process.finishAmount : 1);
        costProcess += costPr;
      }
      else {
        const item = this.tableRecProcure.find((r) => { return r.id == pid; });
        ppu = item.pricePerUnit
        costMat = item.pricePerUnit * materials[i].amount;
        costMaterial += costMat;
      }

      dat.push({
        date: inventory.date,
        itemName: this.getItem(inventory.id).name,
        materialAmount: (materials[i].loss ? (materials[i].amount / (1.0 - materials[i].loss)).toFixed(0) : materials[i].amount.toFixed(0)),
        materialAmountBd: (i == 0 ? this.getBdOfClothProduct(materials[i].inventoryId)['preBd'] : ''),
        finishAmount: (amountFinish > 0 ? materials[i].amount.toFixed(0) : ''),
        finishAmountBd: (i == 0 && amountFinish > 0 ? this.getBdOfClothProduct(materials[i].inventoryId)['postBd'] : ''),
        loss: (status == 'Finished' ? (materials[i].loss * 100).toFixed(1) + '%' : ''),
        ppu: ppu,
        materialCost: (costMat != '' ? costMat.toFixed(0) : ''),
        processCost: (costPr != '' ? costPr.toFixed(0) : ''),
      });
    }

    return {
      costProcess: costProcess,
      costMaterial: costMaterial,
      dat: dat,
    }
  }

  //----------------------------------------------------------------------------------------
  getItem(inventoryId) {
    const inventory = this.tableFactoryInventory.find((r) => { return r.id == inventoryId; });
    if (inventory.itemType == 'Thread') return this.tableThread.find((r) => { return r.id == inventory.itemId; });
    if (inventory.itemType == 'Cloth' || inventory.itemType == 'Dyeing') return this.tableCloth.find((r) => { return r.id == inventory.itemId; });
    if (inventory.itemType == 'Product') return this.tableProduct.find((r) => { return r.id == inventory.itemId; });
    throw "Invalid item type";
  }

  //----------------------------------------------------------------------------------------
  getPid(id) {
    return this.tableFactoryInventory.find((r) => { return r.id == id; }).recordId;
  }

  //----------------------------------------------------------------------------------------
  getDateOfMaterial(id) {
    const pid = this.getPid(id);
    return this.tableRecProcure.find((r) => { return r.id == pid; }).date;
  }

  //----------------------------------------------------------------------------------------
  getAmountBdSumStr(amountBd) {
    if (!amountBd) {
      return {
        str: '',
        sum: 0,
      }
    }

    let str = '', sum = 0;
    for (let i = 0; i < amountBd.length; i++) {
      if (Number(amountBd[i]) > 0) str += amountBd[i] + ',';
      sum += Number(amountBd[i]);
    }
    return {
      str: (str.length > 0 ? str.substring(0, str.length - 1) : ''),
      sum: sum,
    };
  }

  //----------------------------------------------------------------------------------------
  getBdOfMaterial(id) {
    const pid = this.getPid(id);
    const amountBd = JSON.parse(this.tableRecProcure.find((r) => { return r.id == pid; }).amountBd);
    return this.getAmountBdSumStr(amountBd).str;
  }

  //----------------------------------------------------------------------------------------
  getBdOfClothProduct(id) {
    const pid = this.getPid(id);
    const amountBd = JSON.parse(this.tableRecProcess.find((r) => { return r.id == pid; }).amountBd);

    const pre = this.getAmountBdSumStr(amountBd['pre']);
    const post = this.getAmountBdSumStr(amountBd['post']);

    return { preSum: pre.sum, preBd: pre.str, postSum: post.sum, postBd: post.str };
  }

  //----------------------------------------------------------------------------------------
  getMaterialOfProduct(item) {
    const pid = this.getPid(item.inventoryId);
    let materials = [];
    let sumMaterial = 0;

    for (let i = 0; i < this.tableRecDetails.length; i++) {
      if (this.tableRecDetails[i].processId != pid) continue;
      materials.push(this.tableRecDetails[i]);
      sumMaterial += this.tableRecDetails[i].materialAmount;
    }

    const process = this.tableRecProcess.find((r) => { return r.id == pid; });
    if (!process) return;
    const loss = (process.finishAmount != '' ? 1.0 - process.finishAmount / process.materialAmount : false);

    for (let i = 0; i < materials.length; i++) {
      materials[i].amount = item.amount * materials[i].materialAmount / sumMaterial / (loss ? 1.0 - loss : 1);
    }

    return { materials: materials, loss: loss };
  }

  //----------------------------------------------------------------------------------------
  getData(pid) {
    let iid = [];

    for (var i = 0; i < this.tableRecDetails.length; i++) {
      if (this.tableRecDetails[i]['pid'] == pid) {
        iid.push(this.tableRecDetails[i]['inventoryId']);
      }
    }
    return iid;
  }
}

export default {
  CTrace,
};
