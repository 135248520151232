//=====================================================================
// API to access DB
//

export default {
  addRecProcess,
  addRecDetails,
  addFactoryInventory,
  reduceMaterial,
  updateMaterialOfClothOrProduct,
};

import Db from '@/db.js';

//----------------------------------------------------------------------
async function addRecProcess(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'RecProcess',
    [{
      id: data.id,
      factoryId: data.factoryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      materialAmount: data.amount,
      amountBd: data.amountBd.replaceAll('"', '""'),
      cost: data.cost,
      date: data.date,
      colorId: data.colorId,
    }]);
}

//----------------------------------------------------------------------
async function addRecDetails(dbDataFilename, data) {
  const details = [];
  for (let i = 0; i < data.material.length; i++) {
    details.push({
      id: data.id + i,
      processId: data.processId,
      itemType: data.itemType,
      itemId: data.material[i]['materialId'],
      materialAmount: data.amount * data.material[i]['ratioPercent'] / 100.0,
      loss: data.material[i]['lossPercent'] / 100.0,
      inventoryId: data.material[i]['inventoryId'],
      date: data.date,
    });
  }

  return Db.addData(dbDataFilename, 'RecDetails', details);
}

//----------------------------------------------------------------------
async function addFactoryInventory(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'FactoryInventory',
    [{
      id: data.id,
      factoryId: data.factoryId,
      itemType: data.itemType,
      itemId: data.itemId,
      isLen: data.isLen,
      amount: data.amount,
      status: data.status,
      recordId: data.recordId,
      date: data.date,
      colorId: data.colorId,
    }]);
}

//----------------------------------------------------------------------
async function reduceMaterial(dbDataFilename, data) {
  const details = [];
  for (let i = 0; i < data.material.length; i++) {
    const item = await Db.loadDataOne(dbDataFilename, 'FactoryInventory', data.material[i]['inventoryId']);
    item[0].amount -= data.amount * data.material[i]['ratioPercent'] / 100.0;
    details.push({
      id: data.material[i]['inventoryId'],
      amount: item[0].amount,
    });
  }
  return Db.updateData(dbDataFilename, 'FactoryInventory', details);
}

//----------------------------------------------------------------------
async function updateMaterialOfClothOrProduct(dbDataFilename, table, data) {
  return Db.updateData(dbDataFilename, table, [{
    id: data.itemId,
    material: encodeURIComponent(JSON.stringify(data.material)),
  }]);
}