const dbLocation = process.env.VUE_APP_DB_URL;

//------------------------------------------------------------------
export default {
  loadData,
  loadDataOne,
  getLastId,
  addData,
  updateData,
  deleteData,
  arrayToObj,
};

//------------------------------------------------------------------
function loadData(DatabaseName, Table) {
  return new Promise(function (callback) {
    let url = new URL(dbLocation + 'loadFromDb.php');
    let params = {
      DatabaseName: DatabaseName,
      Table: Table,
    };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then((data) => data.json())
      .then((res) => {
        res.forEach((r) => {
          if ('id' in r) r.id = Number(r.id);
        })
        callback(res);
      });
  });
}

//------------------------------------------------------------------
function loadDataOne(DatabaseName, Table, id) {
  return new Promise(function (callback) {
    let url = new URL(dbLocation + 'loadFromDb.php');
    let params = {
      DatabaseName: DatabaseName,
      Table: Table,
      id: id,
    };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then((data) => data.json())
      .then((res) => {
        callback(res);
      });
  });
}

//------------------------------------------------------------------
async function getLastId(DatabaseName) {
  const tables = [
    'RecProcure',
    'RecProcess',
    'RecDetails',
    'RecMove',
    'RecSales',
    'RecPaymentCollect',
    'FactoryInventory',
    'Log',
  ];
  let lastIds = [];

  for (let i = 0; i < tables.length; i++) {
    lastIds.push(await getLastIdOneTable(DatabaseName, tables[i]));
  }
  return Math.max(Math.max.apply(null, (lastIds)));
}

function getLastIdOneTable(DatabaseName, Table) {
  return new Promise(function (callback) {
    let url = new URL(dbLocation + 'getLastId.php');
    let params = {
      DatabaseName: DatabaseName,
      Table: Table,
    };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then((data) => data.json())
      .then((res) => {
        callback(res);
      });
  });
}


//------------------------------------------------------------------
function addData(DatabaseName, Table, Items) {
  return writeToDb(DatabaseName, Table, { type: "addItem", items: Items });
}

//------------------------------------------------------------------
function updateData(DatabaseName, Table, Items) {
  return writeToDb(DatabaseName, Table, { type: "updateItem", items: Items });
}

//------------------------------------------------------------------
function deleteData(DatabaseName, Table, Items) {
  return writeToDb(DatabaseName, Table, { type: "deleteItem", items: Items });
}

//------------------------------------------------------------------
function arrayToObj(arr) {
  let obj = {};
  arr.forEach((r) => { obj[r.id] = r; });
  return obj;
}

//------------------------------------------------------------------
function writeToDb(DatabaseName, Table, Action) {
  return new Promise(function (callback) {
    let params = {
      DatabaseName: DatabaseName,
      Table: Table,
    };
    params[Action.type] = Action.items;

    fetch(dbLocation + 'writeToDb.php', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params),
    })
      .then(response => response.json())
      .then(res => {
        callback(res);
      });
  });
}

