//=====================================================================
// API to access DB
//
export default {
  reduceFactoryInventory,
  addAccountsReceivable,
  addRecSales,
};

import Db from '@/db.js';

//----------------------------------------------------------------------
async function reduceFactoryInventory(dbDataFilename, data) {
  return Db.updateData(dbDataFilename, 'FactoryInventory',
    [{
      id: data.item.id,
      amount: data.item.amount - data.amount,
    }]);
}

//----------------------------------------------------------------------
async function addAccountsReceivable(dbDataFilename, data) {
  const customer = await Db.loadDataOne(dbDataFilename, 'Customer', data.customerId);

  return Db.updateData(dbDataFilename, 'Customer',
    [{
      id: data.customerId,
      amount: customer[0].amount + data.pricePerUnit * data.amount,
    }]);
}

//----------------------------------------------------------------------
async function addRecSales(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'RecSales',
    [{
      id: data.id,
      customerId: data.customerId,
      itemId: data.item.itemId,
      pricePerUnit: data.pricePerUnit,
      amount: data.amount,
      memo: data.memo,
      date: data.date,
    }]);
}
