<template>
  <header>
    <div class="fr_title">{{ titleBar }}</div>

    <nav class="navbar navbar-expand-lg" data-bs-theme="dark">
      <ul class="navbar-nav">
        <li class="nav-item">
          <button aria-expanded="">
            <router-link to="/home">
              <img src="./assets/img/icons/menu/icon-home-w.svg" alt="home"> Home
            </router-link>
          </button>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-data-view-w.svg" alt="data view">{{ DataView }}
          </button>
          <div class="dropdown-menu">
            <p>
                <img src="./assets/img/icons/menu/icon-data-view-o.svg" alt="data view">
                {{ DataView }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/viewFactoryInventory"><button>{{ LabelFactoryInventory }}</button></router-link></li>
                <li><router-link to="/viewRecProcure"><button>{{ LabelRecProcure }}</button></router-link></li>
                <li><router-link to="/viewRecProcess"><button>{{ LabelRecProcess }}</button></router-link></li>
                <li><router-link to="/viewRecDetails"><button>{{ LabelRecDetails }}</button></router-link></li>
                <li><router-link to="/viewRecMove"><button>{{ LabelRecMove }}</button></router-link></li>
                <li><router-link to="/viewRecSales"><button>{{ LabelRecSales }}</button></router-link></li>
                <li><router-link to="/viewRecPaymentCollect"><button>{{ LabelRecPaymentCollect }}</button></router-link></li>
              </ul>
              <ul class="menulist">
                <li><router-link to="/viewSupplierThread"><button>{{ LabelSupplierThread }}</button></router-link></li>
                <li><router-link to="/viewSupplierCloth"><button>{{ LabelSupplierCloth }}</button></router-link></li>
                <li><router-link to="/viewCustomer"><button>{{ LabelCustomer }}</button></router-link></li>
              </ul>
              <ul class="menulist">
                <li><router-link to="/viewFactory"><button>{{ LabelFactory }}</button></router-link></li>
                <li><router-link to="/viewThread"><button>{{ LabelThread }}</button></router-link></li>
                <li><router-link to="/viewCloth"><button>{{ LabelCloth }}</button></router-link></li>
                <li><router-link to="/viewColor"><button>{{ LabelColor }}</button></router-link></li>
                <li><router-link to="/viewProduct"><button>{{ LabelProduct }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-procure-w.svg" alt="Procure">{{ Procure }}
          </button>
          <div class="dropdown-menu">
            <p>
              <img src="./assets/img/icons/menu/icon-procure-o.svg" alt="Procure">
              {{ Procure }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/procureThread">{{ Thread }}</router-link></li>
                <li><router-link to="/procureCloth">{{ Cloth }}</router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-production-w.svg" alt="production">{{ Process }}
          </button>
          <div class="dropdown-menu">
            <p>
              <img src="./assets/img/icons/menu/icon-production-o.svg" alt="production">
              {{ Process }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/startProductionCloth"><button>{{ StartProductionCloth }}</button></router-link></li>
                <li><router-link to="/startDyeingCloth"><button>{{ StartDyeingCloth }}</button></router-link></li>
                <li><router-link to="/startProductionProduct"><button>{{ StartProductionProduct }}</button></router-link></li>
              </ul>
              <ul class="menulist">
                <li><router-link to="/finishProductionCloth"><button>{{ FinishProductionCloth }}</button></router-link></li>
                <li><router-link to="/finishDyeingCloth"><button>{{ FinishDyeingCloth }}</button></router-link></li>
                <li><router-link to="/finishProductionProduct"><button>{{ FinishProductionProduct }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-move-w.svg" alt="move">{{ Move }}
          </button>
          <div class="positonRight dropdown-menu">
            <p>
              <img src="./assets/img/icons/menu/icon-move-o.svg" alt="move">
              {{ Move }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/moveThread"><button>{{ MoveThread }}</button></router-link></li>
                <li><router-link to="/moveCloth"><button>{{ MoveCloth }}</button></router-link></li>
                <li><router-link to="/moveProduct"><button>{{ MoveProduct }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-sales-w.svg" alt="sales">{{ Sales }}
          </button>
          <div class="dropdown-menu">
            <p>
              <img src="./assets/img/icons/menu/icon-sales-o.svg" alt="sales">
              {{ Sales }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/salesProduct"><button>{{ SalesProduct }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-payment-and-collect-w.svg" alt="payment-and-collect">{{ PaymentCollect }}
          </button>
          <div class="dropdown-menu">
            <p>
              <img src="./assets/img/icons/menu/icon-payment-and-collect-o.svg" alt="payment-and-collect">
              {{ PaymentCollect }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/paymentThread"><button>{{ PaymentThread }}</button></router-link></li>
                <li><router-link to="/paymentCloth"><button>{{ PaymentCloth }}</button></router-link></li>
                <li><router-link to="/collectReceivable"><button>{{ CollectReceivable }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-registration-w.svg" alt="registration">{{ Register }}
          </button>
          <div class="dropdown-menu positionRight">
            <p>
              <img src="./assets/img/icons/menu/icon-registration-o.svg" alt="registration">
              {{ Register }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/registerThread"><button>{{ Thread }}</button></router-link></li>
                <li><router-link to="/registerCloth"><button>{{ Cloth }}</button></router-link></li>
                <li><router-link to="/registerColor"><button>{{ Color }}</button></router-link></li>
                <li><router-link to="/registerProduct"><button>{{ Product }}</button></router-link></li>
                <li><router-link to="/registerFactory"><button>{{ Factory }}</button></router-link></li>
              </ul>
              <ul class="menulist">
                <li><router-link to="/registerSupplierThread"><button>{{ SupplierThread }}</button></router-link></li>
                <li><router-link to="/registerSupplierCloth"><button>{{ SupplierCloth }}</button></router-link></li>
                <li><router-link to="/registerCustomer"><button>{{ Customer }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown">
          <button class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="">
            <img src="./assets/img/icons/menu/icon-etc-w.svg" alt="etc">{{ Etc }}
          </button>
          <div class="dropdown-menu positionRight">
            <p>
              <img src="./assets/img/icons/menu/icon-etc-o.svg" alt="data view">
              {{ Etc }}
            </p>
            <div class="menuContainer">
              <ul class="menulist">
                <li><router-link to="/trace"><button>{{ TraceMain }}</button></router-link></li>
                <li><router-link to="/user"><button>{{ UserManagement }}</button></router-link></li>
                <li><router-link to="/log"><button>{{ Log }}</button></router-link></li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  </header>

  <router-view v-on:onLogout="onLogout" />
</template>

<script>
import { mapState } from 'vuex';
import { getLocale } from './store.js';
import router from './router';
import TraceMain from './components/TraceMain.vue';

export default {
  name: 'Main',
  props: {
    isAdmin: Boolean
  },
  emits: ['onLogout'],

  data() {
    return {
      menuSelected: 'DataView',
    };
  },

  computed: mapState({
    titleBar: state => getLocale(state, 1000),

    DataView: state => getLocale(state, 10028),
    LabelFactoryInventory: state => getLocale(state, 220001),
    LabelRecProcure: state => getLocale(state, 20001),
    LabelRecProcess: state => getLocale(state, 30001),
    LabelRecDetails: state => getLocale(state, 200001),
    LabelRecMove: state => getLocale(state, 210001),
    LabelRecPaymentCollect: state => getLocale(state, 40001),
    LabelRecSales: state => getLocale(state, 250001),
    LabelSupplierThread: state => getLocale(state, 120001),
    LabelSupplierCloth: state => getLocale(state, 130001),
    LabelCustomer: state => getLocale(state, 240001),
    LabelFactory: state => getLocale(state, 230001),
    LabelThread: state => getLocale(state, 160001),
    LabelCloth: state => getLocale(state, 170001),
    LabelColor: state => getLocale(state, 260001),
    LabelProduct: state => getLocale(state, 180001),

    Procure: state => getLocale(state, 10026),
    Thread: state => getLocale(state, 160001),
    Cloth: state => getLocale(state, 170001),

    Process: state => getLocale(state, 10027),
    StartProductionCloth: state => getLocale(state, 10004),
    FinishProductionCloth: state => getLocale(state, 10006),
    StartDyeingCloth: state => getLocale(state, 10015),
    FinishDyeingCloth: state => getLocale(state, 10016),
    StartProductionProduct: state => getLocale(state, 10005),
    FinishProductionProduct: state => getLocale(state, 10007),

    Move: state => getLocale(state, 10029),
    MoveThread: state => getLocale(state, 10008),
    MoveCloth: state => getLocale(state, 10009),
    MoveProduct: state => getLocale(state, 10010),

    Sales: state => getLocale(state, 10030),
    SalesProduct: state => getLocale(state, 10013),

    PaymentCollect: state => getLocale(state, 10031),
    PaymentThread: state => getLocale(state, 10011),
    PaymentCloth: state => getLocale(state, 10012),
    CollectReceivable: state => getLocale(state, 10014),

    Register: state => getLocale(state, 10017),
    Color: state => getLocale(state, 260001),
    Product: state => getLocale(state, 180001),
    Factory: state => getLocale(state, 230001),
    SupplierThread: state => getLocale(state, 120001),
    SupplierCloth: state => getLocale(state, 130001),
    Customer: state => getLocale(state, 240001),

    Etc: state => getLocale(state, 10032),
    TraceMain: state => getLocale(state, 10033),
    UserManagement: state => getLocale(state, 10034),
    Log: state => getLocale(state, 10035),
  }),

  methods: {
    onLogout() {
      this.$emit('onLogout');
    },

    onMenuChanged() {
      if (this.menuSelected == 'Home') router.push('/Home');
    },
  },
}

</script>
