<template>
    <input type="radio" name="unit" id="length" value="length" v-model="unit" @change="onChanged">
    <label for="length">{{ LabelLength }}</label>
    <input type="radio" name="unit" id="weight" value="weight" v-model="unit" @change="onChanged">
    <label for="weight">{{ LabelWeight }}</label>
</template>

<script>
import { getLocale } from '../store.js';

export default {
  name: 'unitInput',
  emits: ['unitChanged'],

  data() {
    return {
      unit: "length",
    };
  },

  computed: {
    LabelLength: function () {
      return getLocale(this.$store.state, 10205);
    },
    LabelWeight: function () {
      return getLocale(this.$store.state, 10206);
    },
  },

  methods: {
    onChanged() {
      this.$emit("unitChanged", this.unit);
    },
  },
}

</script>