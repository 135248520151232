//=====================================================================
// API to access DB
//

export default {
    updateRecProcess,
    updateFactoryInventory,
};

import Db from '@/db';

async function updateRecProcess(dbDataFilename, data) {
    const record = await Db.loadDataOne(dbDataFilename, 'RecProcess', data.processId);
    const amountBd = JSON.parse(record[0].amountBd);
    amountBd.post = data.amountBd;

    return Db.updateData(dbDataFilename, 'RecProcess', [{
        id: data.processId,
        finishAmount: data.amount,
        amountBd: JSON.stringify(amountBd).replaceAll('"', '\"'),
        date: data.date,
    }]);
}

async function updateFactoryInventory(dbDataFilename, data) {
    const item = await Db.loadDataOne(dbDataFilename, 'FactoryInventory', data.inventoryId);

    return Db.updateData(dbDataFilename, 'FactoryInventory', [{
        id: data.inventoryId,
        amount: data.amount,
        status: 'Finished',
        date: data.date,
    }]);
}
