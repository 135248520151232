<template>
  <div class="fr_user">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <div class="fr_userInputlist">
          <div class="fr_inputSingle">
            <input type="text" v-model="email" :rules="[rules.required, rules.email]" @change="inputChanged" :placeholder="LabelEmailAddress">
          </div>
          <div class="fr_inputSingle">
            <input type="password" @change="inputChanged" v-model="password" :placeholder="LabelPassword">
          </div>
          <div class="fr_inputSingle">
            <input type="password" v-model="passwordConfirm" @change="inputChanged" :placeholder="LabelPasswordConfirm">
          </div>
          <div class="fr_errMsg" v-if="passwordError">{{ LabelPasswordNotMatch }}</div>
        </div>

        <div class="fr_inputSingle">
          <input :placeholder="LabelDatabase" v-model="database" @change="inputChanged" />
        </div>

        <div class="fr_selecterWrap">
          <select v-model="LBAuthoritySelected">
            <option :value="LBAuthorityNone">{{ LabelAuthority }}</option>
            <option v-for="authority in LBAuthority" :value="authority">{{ authority }}</option>
          </select>
        </div>

        <div class="fr_sectionTitle">{{ LabelExpiration }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="expirationDate" format="yyyy/MM/dd" :enable-time-picker="false" />
          </li>
        </ul>

        <div class="fr_primaryButtonWrap">
          <button v-on:click="add" :disabled="addDisabled">{{ LabelAdd }}</button>
        </div>
      </div>


      <div>
        <div class="fr_sectionTitle">{{ LabelRemove }}</div>
        <div class="fr_primaryButtonWrap buttonWide">
          <button v-on:click="remove">{{ LabelRemove }}</button>
        </div>
        <vue-good-table ref="table" :columns="tableColumns" :rows="users" :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true }" />
      </div>
    </div>
  </div>
</template>

<script>
import Db from "@/db";
import Util from '@/util';
import ColumnInfo from "@/components/ColumnInfo.js";
import { getLocale } from '@/store.js';
import {
  getAuth,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import router from "@/router";

export default {
  name: 'User',

  //----------------------------------------------------------------------
  data() {
    return {
      users: [],

      email: '',
      password: '',
      passwordConfirm: '',
      passwordError: false,
      database: '',
      rules: {
        required: value => !!value || this.MessageEmailRequired,
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.MessageInvalidEmail;
        }
      },
      LBAuthority: ['user', 'admin'],
      LBAuthorityNone: null,
      LBAuthoritySelected: null,

      auth: getAuth(),
      addDisabled: true,

      userdata: null,
      dialog: false,
      expirationDate: this.setInitialExpirationDate(),
    };
  },

  computed: {
    LabelTitle() { return getLocale(this.$store.state, 10034); },
    LabelEmailAddress() { return getLocale(this.$store.state, 270002); },
    LabelPassword() { return getLocale(this.$store.state, 270003); },
    LabelPasswordConfirm() { return getLocale(this.$store.state, 270004); },
    LabelDatabase() { return getLocale(this.$store.state, 270005); },
    LabelAuthority() { return getLocale(this.$store.state, 270006); },
    LabelExpiration() { return getLocale(this.$store.state, 270007); },
    LabelAdd: function () { return getLocale(this.$store.state, 270008); },
    LabelPasswordNotMatch() { return getLocale(this.$store.state, 270009); },
    LabelRemove: function () { return getLocale(this.$store.state, 270010); },
    MessageNewUser: function () { return getLocale(this.$store.state, 270011); },
    MessageConfirmTitle: function () { return getLocale(this.$store.state, 270012); },
    MessageConfirmBody: function () { return getLocale(this.$store.state, 270013); },
    MessageInvalidEmail: function () { return getLocale(this.$store.state, 270014); },
    MessageEmailRequired: function () { return getLocale(this.$store.state, 270015); },
    tableColumns() {
      const columnInfo = ColumnInfo.getColumnInfo('users');
      return this.getTableColumns(columnInfo);
    },
  },

  //----------------------------------------------------------------------
  async created() {
    await this.loadData();
    this.setInitialExpirationDate();
  },
  async mounted() {
    await this.loadData();
    this.setInitialExpirationDate();
  },

  methods: {
    async loadData() {
      Db.loadData(this.$store.getters.userDbFilename, 'users').then((data) => {
        const items = JSON.parse(JSON.stringify(Db.arrayToObj(data)));
        this.users = this.getTableItems(items);
      });
    },

    //----------------------------------------------------------------------
    async add() {
      createUserWithEmailAndPassword(this.auth, this.email, this.password)
        .then((userCredential) => {
          const data = {
            uid: userCredential.user.uid,
            mail: this.email,
            database: this.database,
            authority: this.LBAuthoritySelected,
            expiration: Util.formatDate(this.expirationDate),
          };

          // データベース更新
          Db.addData(this.$store.getters.userDbFilename, 'users', [data]);
          this.users.push(data);

          router.push('/home');
          alert(this.MessageNewUser + ': ' + this.email);
        })
        .catch((error) => {
          alert(error);
        });
    },

    //----------------------------------------------------------------------
    async remove() {
      const id = [];
      this.$refs['table'].selectedRows.forEach((s) => { id.push({ id: s.id }); });
      await Db.deleteData(this.$store.getters.userDbFilename, 'users', id);

      this.$refs['table'].selectedRows.forEach((s) => {
        this.users = this.users.filter(({ id }) => id != s.id );
      });
      this.users = JSON.parse(JSON.stringify(this.users));
    },

    //----------------------------------------------------------------------
    inputChanged() {
      this.passwordError = this.password != this.passwordConfirm;
      this.addDisabled = !this.checkInput();
    },

    checkInput() {
      if (this.email == '') return false;
      if (this.rules.email(this.email) != true) return false;
      if (this.password == '') return false;
      if (this.passwordError) return false;
      if (this.database == '') return false;
      return true;
    },

    //----------------------------------------------------------------------
    getTableColumns(columnInfo) {
      const tableColumns = [];
      Object.keys(columnInfo).forEach((key) => {
        tableColumns.push({
          label: getLocale(this.$store.state, columnInfo[key].labelId),
          field: columnInfo[key].item,
        });
      });
      return tableColumns;
    },

    getTableItems(items) {
      const tableItems = [];
      Object.keys(items).forEach((key) => {
        const data = items[key];
        tableItems.push(data);
      });
      return tableItems;
    },

    setInitialExpirationDate() {
      const now = new Date();
      now.setFullYear(now.getFullYear() + 1);
      this.expirationDate = now;
    },
  },
}

</script>