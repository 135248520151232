//=====================================================================
// API to access DB
//

export default {
  addLog,
};

import Db from '@/db.js';

//----------------------------------------------------------------------
async function addLog(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'Log', [{
    id: data.id,
    datetime: data.datetime,
    pid: data.pid,
    type: data.type,
  }]);
}