<template>
  <div class="fr_procure">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBFactorySelected" v-on:change="OnLBFactoryChanged" return-object>
                <option v-for="factory in LBFactory" :value="factory">{{ factory.name }}</option>
                <option :value="LBFactorySelected" disabled hidden>{{ LabelSelectFactory }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected" v-on:change="OnLBItemChanged">
                <option v-for="item in LBItem" :value="item">{{ item.name }}</option>
                <option :value="LBItemSelected" disabled hidden>{{ LabelSelectItem }}</option>
              </select>
            </div>
          </li>
          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBColorSelected">
                <option v-for="color in LBColor" :value="color">{{ color.name }}</option>
                <option :value="LBColorSelected" disabled hidden>{{ LabelSelectColor }}</option>
              </select>
            </div>

          </li>
        </ul>

        <p>{{ LabelFillInExpect }}</p>
        <ul class="fr_inputboxList">
          <ValueInput v-bind:Values="Array(25).fill(0)" v-on:valueUpdated="valueUpdated" />
        </ul>

        <div class="fr_inputSingleSmall">
          <input type="number" :placeholder="LabelFillInCost" v-model="TBCost">
        </div>

        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>

      </div>
      <a v-if="enableTest" v-on:click="testInput(routeName)">test</a>
    </div>
  </div>

  <div class="fr_historyTable">
    <div class="fr_sectionTitle">{{ LabelRecord }}</div>
    <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
  </div>

</template>

<script>
import Db from '@/db';
import Util from '@/util';
import { getLocale } from '@/store.js';
import ValueInput from '@/components/ValueInput.vue';
import ProcessItems from '@/components/db/ProcessItems.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Log from '@/components/db/Log.js';

export default {
  name: 'Process',
  components: {
    ValueInput,
    ViewDbData,
  },
  props: { route: String },
  emits: ['onLogout'],

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBFactory: [],
      LBFactorySelected: null,
      LBItem: [],
      LBItemSelected: null,
      LBColor: [],
      LBColorSelected: null,
      TBAmount: Array(25).fill(0),
      TBCost: null,

      FactoryInventory: null,
      Cloth: null,
      Color: null,

      update: false,
      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale('LabelTitle') },
    LabelSelectFactory() { return this.getLocale('LabelSelectFactory') },
    LabelSelectItem() { return this.getLocale('LabelSelectItem') },
    LabelSelectColor() { return this.getLocale('LabelSelectColor') },
    LabelFillInExpect() { return this.getLocale('LabelFillInExpect') },
    LabelFillInCost() { return this.getLocale('LabelFillInCost') },
    CBExecute() { return this.getLocale('CBExecute') },
    LabelRecord() { return this.getLocale('LabelRecord'); },
    Complete() { return this.getLocale('Complete') },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      // 現在の処理に応じた工場種類の工場を工場選択リストに入れる
      const tableFactory = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      this.LBFactory = tableFactory.filter((item) => { return item.type == 'Dyeing'; });

      // 工場在庫
      this.FactoryInventory = await Db.loadData(this.$store.getters.dbDataFilename, 'FactoryInventory');

      // 生地
      this.Cloth = await Db.loadData(this.$store.getters.dbDataFilename, 'Cloth');

      // 色
      this.Color = await Db.loadData(this.$store.getters.dbDataFilename, 'Color');
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      // データベース書き込みの共通パラメーター
      const dbDataCommon = {
        factoryId: this.LBFactorySelected.id,
        itemType: 'Dyeing',
        itemId: this.LBItemSelected.itemId,
        isLen: this.LBItemSelected.isLen,
        material: [{
          inventoryId: this.LBItemSelected.id,
          materialId: this.LBItemSelected.itemId,
          ratioPercent: 100,
          lossPercent: 0,
        }],
        amount: Util.getTotal(this.TBAmount),
        amountBd: JSON.stringify({ pre: Util.getTotalBd(this.TBAmount) }),
        cost: this.TBCost,
        status: 'Processing',
        colorId: this.LBColorSelected.id,
        date: Util.formatDate(new Date(this.date)),
      };
      const lastId = await Db.getLastId(this.$store.getters.dbDataFilename);
      const recProcessId = lastId + 1;
      const recDetailId = recProcessId + 1;
      const factoryInventoryId = recDetailId + 1;
      const logId = factoryInventoryId + 1;

      // 加工記録に追加
      const responseAddRecProcess = await ProcessItems.addRecProcess(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recProcessId,
        });
      if (responseAddRecProcess.result != "ok") {
        alert("fail\n" + responseAddRecProcess.error.errorInfo);
        return false;
      }

      // 加工明細に追加
      const responseAddRecDetails = await ProcessItems.addRecDetails(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recDetailId,
          processId: recProcessId,
        });
      if (responseAddRecDetails.result != "ok") {
        alert("fail\n" + responseAddRecDetails.error.errorInfo);
        return false;
      }

      // 工場在庫に追加
      const responseAddFactoryInventory = await ProcessItems.addFactoryInventory(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: factoryInventoryId,
          recordId: recProcessId,
        });
      if (responseAddFactoryInventory.result != "ok") {
        alert("fail\n" + responseAddFactoryInventory.error.errorInfo);
        return false;
      }

      // 工場在庫の材料を減少
      const responseReduceMaterial = await ProcessItems.reduceMaterial(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
        }
      );
      if (responseReduceMaterial.result != "ok") {
        alert("fail\n" + responseReduceMaterial.error.errorInfo);
        return false;
      }

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename, {
        id: logId,
        datetime: Date().toString(),
        pid: recProcessId,
        type: this.routeName,
      });
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }

      // 完了メッセージ表示
      alert(this.Complete);

      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    OnLBFactoryChanged() {
      // 生産品リストを更新
      const inventory = this.FactoryInventory.filter((item) => {
        if (item.factoryId != this.LBFactorySelected.id) return false;
        if (item.itemType != 'Cloth') return false;
        if (item.status == 'Processing') return false;
        if (item.colorId != 0) return false;
        return true;
      });
      inventory.forEach((item) => {
        item.name = this.Cloth.find(({ id }) => id == item.itemId).name
          + ' / ' + item.amount + (item.isLen ? 'm' : 'kg');
      });
      this.LBItem = JSON.parse(JSON.stringify(inventory));
    },

    OnLBItemChanged() {
      const cloth = this.Cloth.find(({ id }) => id == this.LBItemSelected.itemId);
      const color = JSON.parse(cloth.colorId);
      this.LBColor = this.Color.filter(({ id }) => color.includes(id));
    },

    //----------------------------------------------------------------------
    valueUpdated(amount) {
      this.TBAmount = amount;
    },

    dataUpdated() {
      this.update = false;
    },

    //----------------------------------------------------------------------
    getLocale(label) {
      if (label == 'LabelTitle') return getLocale(this.$store.state, 10015);
      if (label == 'LabelSelectFactory') return getLocale(this.$store.state, 11601);
      if (label == 'LabelSelectItem') return getLocale(this.$store.state, 11602);
      if (label == 'LabelSelectColor') return getLocale(this.$store.state, 11603);
      if (label == 'LabelFillInExpect') return getLocale(this.$store.state, 11604);
      if (label == 'LabelFillInCost') return getLocale(this.$store.state, 11607);
      if (label == 'CBExecute') return getLocale(this.$store.state, 11608);
      if (label == 'Complete') return getLocale(this.$store.state, 11609);
    },

    //----------------------------------------------------------------------
    async testInput() {
      this.date = Util.formatDate(new Date());

      this.LBFactorySelected = this.LBFactory[0];
      this.OnLBFactoryChanged();

      this.LBItemSelected = this.LBItem[0];
      this.OnLBItemChanged();

      this.LBColorSelected = this.LBColor[0];
      this.TBAmount = Array(25).fill(0);
      this.TBAmount[0] = 1500;
      this.TBAmount[1] = 1500;
      this.TBCost = 1200;

      await this.execute();
    },
  },
}

</script>
