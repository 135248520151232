//=====================================================================
// API to access DB
//

export default {
  addRecMove,
  addFactoryInventory,
  reduceFactoryInventory,
};

import Db from '@/db.js';

//----------------------------------------------------------------------
async function addRecMove(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'RecMove',
    [{
      id: data.id,
      srcId: data.src.id,
      dstId: data.dst.id,
      itemType: data.itemType,
      itemId: data.item.itemId,
      isLen: data.item.isLen,
      amount: data.amount,
      amountBd: data.amountBd.replaceAll('"', '""'),
      date: data.date,
    }]);
}

//----------------------------------------------------------------------
async function addFactoryInventory(dbDataFilename, data) {
  return Db.addData(dbDataFilename, 'FactoryInventory',
    [{
      id: data.id,
      factoryId: data.dst.id,
      itemType: data.itemType,
      itemId: data.item.itemId,
      isLen: data.item.isLen,
      amount: data.amount,
      status: data.item.status,
      recordId: data.item.recordId,
      date: data.date,
      colorId: data.item.colorId,
    }]);
}

//----------------------------------------------------------------------
async function reduceFactoryInventory(dbDataFilename, data) {
  return Db.updateData(dbDataFilename, 'FactoryInventory',
    [{
      id: data.item.id,
      amount: data.item.amount - data.amount,
    }]);
}
