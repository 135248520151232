//------------------------------------------------------------------
export default {
  getTotal,
  getTotalBd,
  formatDate,
  formattedDate,
  getUnitString,
  getAmountBdSimple,
};

//----------------------------------------------------------------------
function getTotal(values) {
  let total = 0;
  values.forEach((r) => { total += Number(r); });
  return total;
}

function getTotalBd(values) {
  let amount = [];
  values.forEach((r) => { amount.push(Number(r)) });
  return amount;
}

//----------------------------------------------------------------------
function formatDate(dt) {
  var y = dt.getFullYear();
  var m = ('00' + (dt.getMonth() + 1)).slice(-2);
  var d = ('00' + dt.getDate()).slice(-2);
  return (y + '-' + m + '-' + d);
}

function formattedDate(date) {
  return date.toLocaleDateString("ja-JP", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).split("/").join("-");
}

function getUnitString(unit) {
  if (unit == 'length') return 'm';
  else return 'kg';
}

//----------------------------------------------------------------------
function getAmountBdSimple(amountBd) {
  let ss = '';
  amountBd.forEach((v) => {
    if (v > 0) ss += v + ', ';
  });
  if (ss.length > 0) ss = ss.slice(0, -2);
  return ss;
}
