import { createApp } from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import vuetify from './vuetify.js';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import './assets/css/styles.css';
import VueGoodTablePlugin from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZAG-069YSi6a6e14h_BWPLqEqAPQq9WY",
  authDomain: "swatchai-81692.firebaseapp.com",
  projectId: "swatchai-81692",
  storageBucket: "swatchai-81692.appspot.com",
  messagingSenderId: "175353220726",
  appId: "1:175353220726:web:eda48230114f54a89f1957",
  measurementId: "G-KVZTX55PVG"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueGoodTablePlugin);
//app.use(vuetify);
app.component('VueDatePicker', VueDatePicker);

await store.dispatch('initLocale');
store.dispatch('setLang', (process.env.NODE_ENV == 'development' ? 'jp' : 'kr'));

app.mount('#app');

