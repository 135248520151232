<template>
  <div class="fr_salesProduct">
    <div class="fr_pageTitle">{{ LabelTitle }}</div>
    <div class="fr_entryForm">
      <div>
        <div class="fr_sectionTitle">{{ LabelTitle }}</div>
        <ul class="fr_selectList">
          <li class="fr_datapickerWrap">
            <VueDatePicker v-model="date" format="yyyy/MM/dd" :enable-time-picker="false" light />
          </li>

          <li>
            <div class="fr_selecterWrap">
              <select v-model="LBItemSelected" v-on:change="OnLBItemChanged">
                <option v-for="item in LBItem" :value="item">{{ item.name }}</option>
                <option :value="LBItemSelected" disabled hidden>{{ LabelSelectItem }}</option>
              </select>
            </div>
          </li>

          <li>
           <div class="fr_selecterWrap">
              <select id="SelectDelivery" v-model="LBCustomerSelected">
                <option v-for="customer in LBCustomer" :value="customer">{{ customer.name }}</option>
                <option :value="LBCustomerSelected" disabled hidden>{{ LabelSelectCustomer }}</option>
              </select>
            </div>
          </li>

        </ul>
        <ul class="fr_selectList">
          <li>
            <div>
              <input type="number" v-model="TBPricePerUnit" :placeholder="LabelFillInPricePerUnit">
            </div>
          </li>

          <li>
            <div>
              <input type="number" v-model="TBAmount" :placeholder="LabelAmount" />
              <input type="text" :value="LabelUnit" readonly />
            </div>
          </li>
        </ul>

        <div class="fr_primaryButton">
          <a v-on:click="execute">{{ CBExecute }}</a>
        </div>
      </div>
      <button v-if="enableTest" v-on:click="testInput(routeName)">test</button>
    </div>

    <div class="fr_historyTable">
      <div class="fr_sectionTitle">{{ LabelRecord }}</div>
      <ViewDbData v-bind:update="update" v-bind:enableSelect="false" v-bind:route="routeName" v-on:dataUpdated="dataUpdated" />
    </div>
  </div>
</template>

<script>
import Db from '@/db';
import { getLocale } from '@/store.js';
import Util from '@/util';
import SalesItems from '@/components/db/SalesItems.js';
import ViewDbData from '@/components/ViewDbData.vue';
import Log from '@/components/db/Log.js';

export default {
  name: 'Sales',
  components: {
    ViewDbData,
  },
  props: { route: String },

  //----------------------------------------------------------------------
  data() {
    return {
      routeName: null,

      date: new Date(),
      LBItem: [],
      LBItemSelected: null,
      LBCustomer: [],
      LBCustomerSelected: null,
      TBPricePerUnit: null,
      TBAmount: null,
      LabelUnit: '',

      update: false,

      enableTest: process.env.NODE_ENV === 'development',
    };
  },

  //----------------------------------------------------------------------
  computed: {
    LabelTitle() { return this.getLocale('LabelTitle'); },
    LabelSelectItem() { return this.getLocale('LabelSelectItem'); },
    LabelSelectCustomer() { return this.getLocale('LabelSelectCustomer'); },
    LabelFillInPricePerUnit() { return this.getLocale('LabelFillInPricePerUnit'); },
    LabelAmount() { return this.getLocale('LabelAmount'); },
    LabelRecord() { return this.getLocale('LabelRecord') },
    CBExecute() { return this.getLocale('CBExecute'); },
    Complete() { return this.getLocale('Complete'); },
  },

  //----------------------------------------------------------------------
  created() {
    this.routeName = this.route;
  },
  updated() {
    this.routeName = this.route;
  },

  //----------------------------------------------------------------------
  watch: {
    routeName: async function () {
      this.LBCustomer = await Db.loadData(this.$store.getters.dbDataFilename, 'Customer');

      const Factory = await Db.loadData(this.$store.getters.dbDataFilename, 'Factory');
      const HQ = Factory.find(({ type }) => type == 'HQ').id;
      const Products = await Db.loadData(this.$store.getters.dbDataFilename, 'Product');
      const Color = await Db.loadData(this.$store.getters.dbDataFilename, 'Color');

      // 工場在庫
      const FactoryInventory = await Db.loadData(this.$store.getters.dbDataFilename, 'FactoryInventory');
      const inventory = FactoryInventory.filter((item) => {
        if (item.factoryId != HQ) return false;
        if (item.amount <= 0) return false;
        if (item.status == 'Processing') return false;
        if (item.itemType != 'Product') return false;
        return true;
      });
      inventory.forEach((item) => {
        const product = Products.find(({ id }) => id == item.itemId);
        const colorId = item.colorId ? item.colorId : 0;
        item.name = product.name + ' / ' + Color.find(({ id }) => id == colorId).name
          + ' / ' + item.amount + (item.isLen ? 'm' : 'kg');
      });
      this.LBItem = JSON.parse(JSON.stringify(inventory));
    }
  },

  //----------------------------------------------------------------------
  methods: {
    async execute() {
      // データベース書き込みの共通パラメーター
      const dbDataCommon = {
        item: this.LBItemSelected,
        customerId: this.LBCustomerSelected.id,
        pricePerUnit: this.TBPricePerUnit,
        amount: this.TBAmount,
        date: Util.formatDate(new Date(this.date)),
      };

      const recSalesId = await Db.getLastId(this.$store.getters.dbDataFilename) + 1;
      const logId = recSalesId + 1;

      // 工場在庫を減らす
      const responseReduceFactoryInventory = await SalesItems.reduceFactoryInventory(
        this.$store.getters.dbDataFilename, dbDataCommon);
      if (responseReduceFactoryInventory.result != "ok") {
        alert("fail\n" + responseReduceFactoryInventory.error.errorInfo);
        return false;
      }

      // 売掛金を増やす
      const responseAddAccountsReceivable = await SalesItems.addAccountsReceivable(
        this.$store.getters.dbDataFilename, dbDataCommon);
      if (responseAddAccountsReceivable.result != "ok") {
        alert("fail\n" + responseAddAccountsReceivable.error.errorInfo);
        return false;
      }

      // 売上記録を追加する
      const responseAddRecSales = await SalesItems.addRecSales(
        this.$store.getters.dbDataFilename,
        {
          ...dbDataCommon,
          id: recSalesId,
        });
      if (responseAddRecSales.result != "ok") {
        alert("fail\n" + responseAddRecSales.error.errorInfo);
        return false;
      }

      // ログを追加
      const responseAddLog = await Log.addLog(
        this.$store.getters.dbDataFilename, {
        id: logId,
        datetime: Date().toString(),
        pid: recSalesId,
        type: this.routeName,
      });
      if (responseAddLog.result != "ok") {
        alert("fail\n" + responseAddLog.error.errorInfo);
        return false;
      }          

      // 完了メッセージ表示
      alert(this.getLocale('Complete'));

      this.update = true;
      return true;
    },

    //----------------------------------------------------------------------
    dataUpdated() {
      this.update = false;
    },

    OnLBItemChanged() {
      if (this.LBItemSelected == null) {
        LabelUnit = '';
        return;
      }
      this.LabelUnit = (this.LBItemSelected.isLen ? 'm' : 'kg');
    },

    //----------------------------------------------------------------------
    getLocale(label) {
      if (label == 'LabelTitle') return getLocale(this.$store.state, 10013);
      if (label == 'LabelSelectItem') return getLocale(this.$store.state, 11301);
      if (label == 'LabelSelectCustomer') return getLocale(this.$store.state, 11302);
      if (label == 'LabelFillInPricePerUnit') return getLocale(this.$store.state, 11303);
      if (label == 'LabelAmount') return getLocale(this.$store.state, 11304);
      if (label == 'LabelRecord') return getLocale(this.$store.state, 10420);
      if (label == 'CBExecute') return getLocale(this.$store.state, 11307);
      if (label == 'Complete') return getLocale(this.$store.state, 11309);
    },

    //----------------------------------------------------------------------
    async testInput() {
      this.date = Util.formatDate(new Date());

      this.LBItemSelected = this.LBItem[0];
      this.LBCustomerSelected = this.LBCustomer[0];
      this.TBPricePerUnit = 1000;
      this.TBAmount = 200;

      await this.execute();
    },
  },
}

</script>